import {defineStore} from 'pinia';

export const makeTagsStore = (id = 'tags') => defineStore(id, {
  state: () => ({
    tags: null,
    hasMore: false,
    cursor: undefined,
    collectionDescriptor: null,
    tag: null
  }),

  actions: {
    async getTags({nextPage, search, userId, order} = {}) {
      if (!nextPage) {
        this.clearTags();
        this.collectionDescriptor = {userId, search, order};
      }

      const data = await useTagApi().getTags({
        cursor: this.cursor,
        ...this.collectionDescriptor
      });

      this.tags = [...(this.tags || []), ...data.tags];
      this.hasMore = data.tags.length === data.per_page;
      this.cursor = data.next_cursor;

      return this.tags;
    },

    //gets a detail tag for tags list (for context actions in list items)
    async getTagsOne({id, userId, shareToken}) {
      const tag = await useTagApi().getTag({id, userId, shareToken});
      const updateIndex = this.tags?.findIndex(item => item.id === tag.id);

      if (updateIndex > -1) {
        this.tags.splice(updateIndex, 1, tag);
      }

      return tag;
    },

    async updateTag({id, updates}) {
      const updatedTag = await useTagApi().updateTag({id, payload: updates});

      if (this.tag && id === this.tag.id) {
        Object.assign(this.tag, updatedTag);
      }

      const listTag = this.tags?.find(a => a.id === id);

      if (listTag) {
        Object.assign(listTag, updatedTag);
      }

      return updatedTag;
    },

    async getTag({id, userId, shareToken}) {
      const tag = await useTagApi().getTag({id, userId, shareToken});

      tag.isOwnedByUser = useUserContext({entity: tag}).isEntityOwner.value;

      if (this.tag?.id === id) {
        Object.assign(this.tag, tag);
      } else {
        this.tag = tag;
      }

      return this.tag;
    },

    clearTags() {
      this.tags = null;
      this.hasMore = false;
      this.cursor = undefined;
      this.collectionDescriptor = null;
    },

    async mergeTags({source, destination}) {
      await useTagApi().mergeTags({
        source,
        destination
      });

      if (this.tags?.length) {
        this.tags = this.tags.filter(tag => tag.id !== source.id);
      }

      const filesStore = useFilesStore();
      filesStore.updateContext({contextId: destination.id, contextType: FILE_CONTEXTS.tag});
      filesStore.refreshFileDetails({updatedTag: source});
    },

    async destroyTags({ids}) {
      await useTagApi().destroyTags({ids});
      this.tags = this.tags?.filter(a => !ids.includes(a.id));
    },

    async disableShareToken({id}) {
      await useTagApi().disableShareToken({id});

      if (this.tag) {
        this.tag.share_token = null;
      }

      this.tags?.forEach(item => {
        if (item.id === id) {
          item.share_token = null;
        }
      });
    },

    async resetShareToken({id}) {
      const newToken = await useTagApi().resetShareToken({id});

      if (this.tag) {
        this.tag.share_token = newToken;
      }

      this.tags?.forEach(item => {
        if (item.id === id) {
          item.share_token = newToken;
        }
      });
    }
  }
});

export const useTagsStore = makeTagsStore();
